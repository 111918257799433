import indexStyles from '../sass/common.scss';
let commons = require('./utils/commons');
import customFAQJS from './includes/custom_faq.js';
import aboutBkgIMG from '../img/about-bkg.png';
import servicesBkgIMG from '../img/services-bkg.png';
import studyBkgIMG from '../img/study-bkg.png';
import pricelistBkgIMG from '../img/pricelist-bkg.png';
import reviewsBkgIMG from '../img/reviews-bkg.png';
import portfolioBkgIMG from '../img/porfolio-bkg.png';
import blogBkgIMG from '../img/blog-bkg.png';
import actionsListBkgIMG from '../img/actions-list-bkg.png';
import contactsBkgIMG from '../img/contacts-bkg.png';
import gallery1IMG from '../img/gallery-1.png';
import gallery2IMG from '../img/gallery-2.png';
import gallery3IMG from '../img/gallery-3.png';
import gallery4IMG from '../img/gallery-4.png';
import cert1IMG from '../img/cert-1.png';
import cert2IMG from '../img/cert-2.png';
import cert3IMG from '../img/cert-3.png';
import banner1IMG from '../img/banner-1.png';
import actions1IMG from '../img/actions-1.png';
import actions2IMG from '../img/actions-2.png';
import actions3IMG from '../img/actions-3.png';
import serviceDetail1IMG from '../img/service-detail-1.png';
import serviceExample1IMG from '../img/service-example-1.png';
import serviceExample2IMG from '../img/service-example-2.png';
import serviceExample3IMG from '../img/service-example-3.png';
import serviceExample4IMG from '../img/service-example-4.png';
import serviceExample5IMG from '../img/service-example-5.png';
import serviceExample6IMG from '../img/service-example-6.png';
import studyPromoBkgIMG from '../img/study-promo-bkg.png';
import aboutStudy1IMG from '../img/about-study-1.png';
import aboutStudy2IMG from '../img/about-study-2.png';
import videoReview1IMG from '../img/video-review-1.png';
import videoReview2IMG from '../img/video-review-2.png';
import videoReview3IMG from '../img/video-review-3.png';
import videoReview4IMG from '../img/video-review-4.png';
import videoReview5IMG from '../img/video-review-5.png';
import videoReview6IMG from '../img/video-review-6.png';
import textReview1IMG from '../img/text-review-1.png';
import textReview2IMG from '../img/text-review-2.png';
import textReview3IMG from '../img/text-review-3.png';
import portfolio1IMG from '../img/portfolio-1.png';
import portfolio2IMG from '../img/portfolio-2.png';
import portfolio3IMG from '../img/portfolio-3.png';
import portfolio4IMG from '../img/portfolio-4.png';
import portfolio5IMG from '../img/portfolio-5.png';
import portfolio6IMG from '../img/portfolio-6.png';
import portfolio7IMG from '../img/portfolio-7.png';
import portfolio8IMG from '../img/portfolio-8.png';
import portfolioCategory1IMG from '../img/portfolio-category-1.png';
import portfolioCategory2IMG from '../img/portfolio-category-2.png';
import portfolioCategory3IMG from '../img/portfolio-category-3.png';
import portfolioCategory4IMG from '../img/portfolio-category-4.png';
import portfolioCategory5IMG from '../img/portfolio-category-5.png';
import portfolioCategory6IMG from '../img/portfolio-category-6.png';
import notFoundIIMG from '../img/404.png';
import blog1IMG from '../img/blog-1.png';
import blog2IMG from '../img/blog-2.png';
import blog3IMG from '../img/blog-3.png';
import blog4IMG from '../img/blog-4.png';
import blog5IMG from '../img/blog-5.png';
import blog6IMG from '../img/blog-6.png';
import blog7IMG from '../img/blog-7.png';
import blog8IMG from '../img/blog-8.png';
import blog9IMG from '../img/blog-9.png';
import actionsDetail1IMG from '../img/actions-detail-1.png';
import blogDetail1IMG from '../img/blog-detail-1.png';
import blogDetail2IMG from '../img/blog-detail-2.png';
import porfolioЬini1IMG from '../img/porfolio-mini-1.png';
import porfolioЬini2IMG from '../img/porfolio-mini-2.png';
import porfolioЬini3IMG from '../img/porfolio-mini-3.png';
import porfolioЬini4IMG from '../img/porfolio-mini-4.png';
import porfolioЬini5IMG from '../img/porfolio-mini-5.png';
import porfolioЬini6IMG from '../img/porfolio-mini-6.png';
import blogPreview1IMG from '../img/blog-preview-1.png';
import blogPreview2IMG from '../img/blog-preview-2.png';
import blogPreview3IMG from '../img/blog-preview-3.png';
